<template>
  <div class="container-xl" v-if="!$store.getters.isForbidden">
    <div class="card col-lg-12 mt-2">
      <div class="card-header flex justify-content-between">
        <div class="card-title">V-Chat Channel</div>
        <div class="flex flex-row mb-2 pr-2 float-right">
          <el-button type="primary" v-b-modal.add size="small"
            ><font-awesome-icon icon="search" /> Create Channel</el-button
          >
        </div>
      </div>
      <div class="table-responsive">
        <table class="table card-table text-nowrap table-vcenter">
          <thead>
            <tr>
              <th width="20%">Channel Name</th>
              <th width="20%">Type</th>
              <th width="20%">Avatar</th>
              <th width="10%">Is Online</th>
              <th>Status</th>
              <th>Created at</th>
            </tr>
          </thead>
          <tbody v-loading="loading.table">
            <tr v-for="item in listcategory" :key="item._id">
              <td>{{ item.name }}</td>
              <td>{{ item.type }}</td>
              <td>
                <template v-if="item.avatar">
                  <img
                    :src="item.avatar"
                    alt="Avatar"
                    class="card-img-top"
                    style="width: 70px; height: 60px"
                  />
                </template>
                <template v-else> - </template>
              </td>
              <td class="text-nowrap text-muted child2-sticky">
                <span class="tag" :class="item.is_online_color">{{
                  item.is_online_str
                }}</span>
              </td>
              <td class="text-nowrap text-muted child2-sticky">
                <span class="tag" :class="item.status_color">{{
                  item.status_str
                }}</span>
              </td>
              <td>{{ item.created_at_str || "-" }}</td>
            </tr>
          </tbody>
        </table>
        <el-empty
          description="No data found"
          v-if="total_rows == 0"
          :image="emptyStateImage"
        ></el-empty>
      </div>
      <div class="card-footer d-flex justify-content-between">
        <small
          >Showing {{ showingFrom }} to {{ showingUntil }} of
          {{ total_rows }} entries</small
        >
        <div class="inline-table">
          <b-pagination
            class="table-cell"
            @change="pageChangeHandler"
            :per-page="size"
            :limit="5"
            :total-rows="total_rows"
            v-model="page"
          />
        </div>
      </div>
    </div>
    <div></div>
    <b-modal
      size="xl"
      id="add"
      title="Create Channel"
      hide-footer
      v-model="modalCreateChannel"
      @hide="closeModalChannel"
    >
      <b-form @submit.prevent="createChannel" @reset="closeModalChannel">
        <b-form-group label="Name">
          <el-input placeholder="Name" v-model="channel.name" />
        </b-form-group>
        <b-form-group label="Type">
          <el-select class="w-full" v-model="channel.type" placeholder="Type">
            <el-option
              v-for="type in type_option"
              :key="type.value"
              :value="type.value"
              :label="type.name"
            >
            </el-option>
          </el-select>
        </b-form-group>
        <b-form-group label="Avatar">
          <b-form-file
            v-model="upload.image"
            :state="Boolean(upload.image)"
            class="mt-2 border-1 p-2"
            accept=".png, .jpg, .jpeg"
            @input="onChangeFile($event, 'image')"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>

          <small class="text-warning"
            ><i>*Submit an avatar before creating a channel</i></small
          ><br />
          <el-button @click="uploadImage" type="" size="small" plain round>
            Submit Avatar
          </el-button>
        </b-form-group>
        <b-form-group label="Is Online">
          <el-select
            class="w-full"
            v-model="channel.is_online"
            placeholder="is online"
          >
            <el-option
              v-for="category in online_option"
              :key="category.value"
              :value="category.value"
              :label="category.name"
            >
            </el-option>
          </el-select>
        </b-form-group>
        <div class="d-flex flex-row float-right">
          <el-button
            @click="createChannel"
            class="mr-2 ml-2"
            size="small"
            type="primary"
            :disabled="!isFormValid"
            >Submit</el-button
          >
          <el-button @click="onHide" size="small" class="mr-2"
            >Cancel</el-button
          >
        </div>
      </b-form>
    </b-modal>

    <b-modal
      size="xl"
      id="edit"
      title="Update Channel"
      hide-footer
      v-model="modalUpdateChannel"
      @hide="closeModalChannel"
    >
      <b-form @submit.prevent="createChannel" @reset="closeModalChannel">
        <b-form-group label="Title">
          <el-input placeholder="Title" v-model="selected_item.title" />
        </b-form-group>
        <b-form-group label="image_url">
          <el-input placeholder="image_url" v-model="selected_item.image_url" />
        </b-form-group>
        <b-form-group label="target_url">
          <el-input
            placeholder="target_url"
            v-model="selected_item.target_url"
          />
        </b-form-group>
        <b-form-group label="target_page">
          <el-input
            placeholder="target_page"
            v-model="selected_item.target_page"
          />
        </b-form-group>
        <b-form-group label="description">
          <el-input
            placeholder="description"
            v-model="selected_item.description"
          />
        </b-form-group>
        <b-form-group label="company_ids">
          <el-input
            placeholder="company_ids"
            v-model="selected_item.company_ids"
          />
        </b-form-group>
        <b-form-group label="user_ids">
          <el-input placeholder="user_ids" v-model="selected_item.user_ids" />
        </b-form-group>
        <b-form-group label="user_type">
          <el-select
            class="w-full"
            v-model="selected_item.user_type"
            placeholder="user_type"
          >
            <el-option
              v-for="category in online_option"
              :key="category.value"
              :value="category.value"
              :label="category.name"
            >
            </el-option>
          </el-select>
        </b-form-group>
        <div class="d-flex flex-row float-right">
          <el-button
            @click="createChannel"
            class="mr-2 ml-2"
            size="small"
            type="primary"
            >Submit</el-button
          >
          <el-button @click="onHide" size="small" class="mr-2"
            >Cancel</el-button
          >
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import moment from "moment";
import {
  CREATE_CHANNEL,
  GET_CHANNEL,
  UPLOAD_IMAGE,
} from "@/store/modules/channel";
import emptyStateImage from "@/assets/images/empty-state.png";
import popupErrorMessages from "@/library/popup-error-messages";
import Pagination from "../components/Pagination.vue";
import { GET_COMPANIES } from "@/store/modules/companies";
import base64File from "@/library/base64File";
import imageCompress from "@/library/vue-image-compression";
export default {
  components: { Pagination },
  name: "Channel",
  metaInfo: {
    title: "Channel",
  },
  data() {
    return {
      image: null,
      image_base64: null,
      upload: {
        image: "",
      },
      user: {
        search_by: "full_name",
        search_keyword: "",
        searchType: 1,
        search_options: [
          { value: "full_name", text: "Full Name" },
          { value: "email", text: "Email" },
          { value: "phone_number", text: "Phone Number" },
        ],
      },
      channel: {
        name: "",
        type: "",
        avatar: "",
        is_online: "",
        company_id: "",
      },
      companies: {
        rows: [],
      },
      isLoadingHistory: false,
      emptyStateImage,
      modalCreateChannel: false,
      closeModalChannel: false,
      modalUpdateChannel: false,
      showSearch: true,
      search_options: [
        { value: "full_name", text: "Name" },
        { value: "email", text: "Email" },
        { value: "phone_number", text: "Phone Number" },
      ],
      online_option: [
        {
          value: false,
          label: "False",
        },
        {
          value: true,
          label: "True",
        },
      ],
      type_option: [
        {
          value: "platform",
          label: "Platform",
        },
      ],
      per_page: 20,
      size: 0,
      total_rows: 0,
      page: 1,
      list: [],
      isLoading: true,
      loading_detail: false,
      loader: null,
      search_keyword: "",
      search_by: "",
      listcategory: [],
      data_image: "",
      timeoutSearchCompany: null,
      selected_item: {
        title: "",
        image_url: "",
        target_url: "",
        target_page: [],
        description: "",
        user_type: "",
        company_ids: [],
        user_ids: [],
      },
      file_tmp: "",
      loading: {
        summary_vendor: false,
      },
      search: {
        date: [
          moment().subtract(30, "day").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
        search_by: "full_name",
        search_keyword: "",
        start_date: moment().subtract(30, "day").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
        status_filter: "",
      },
      datePickerOptions: {
        disabledDate: (date) => moment(date).isAfter(moment(), "days"),
      },
    };
  },
  computed: {
    showingFrom() {
      return this.total_rows ? (this.page - 1) * this.per_page + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.listcategory.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
    isFormValid() {
      return (
        this.channel.name &&
        this.channel.type &&
        this.upload.image &&
        this.channel.is_online !== null
      );
    },
  },
  async mounted() {
    const loader = this.$loading.show();
    await this.getList();
    loader.hide();
    const savedImage = localStorage.getItem("uploadedImage");
    if (savedImage) {
      this.deata_image = JSON.parse(savedImage);
    }
  },
  methods: {
    async loadCompanies({ search_keyword = "" } = {}) {
      this.loading.company = true;
      await this.$store.dispatch(GET_COMPANIES, {
        search_keyword,
      });
      this.loading.company = false;
      const { rows } = this.$store.getters.companies;
      this.companies.rows = this.companies.rows.filter((v) =>
        this.form.company_id.includes(v.company_id)
      );
      this.companies.rows.push(...rows);
    },
    searchCompany(value) {
      clearTimeout(this.timeoutSearchCompany);
      this.timeoutSearchCompany = setTimeout(() => {
        this.loadCompanies({
          search_keyword: value,
        });
      }, 1000);
    },
    handlerImageUpload(event) {
      const file = event.target.files[0];
      this.convertImageToURL(file);
    },
    convertImageToURL(file) {
      const reader = new FileReader();
      reader.onload = () => {
        const imageURL = reader.result;
        this.form.image = imageURL;
      };
      reader.readAsDataURL(file);
    },
    async onChangeFile(event, target) {
      console.log("target image ==>", target);
      if (this.upload[target]) {
        const compressed = await imageCompress(this.upload[target]);
        this.upload[`${target}_base64`] = await base64File(compressed);
        this.file_tmp = this.upload[`${target}_base64`];
      }
    },
    async detailChannel(item) {
      this.selected_item = item;
      this.modalCreateChannel = true;
    },
    async doUpdateChannel() {
      this.$confirm(
        "You will update the Event. Make sure you have input the data correctly. Continue?",
        "Confirmation",
        {
          type: "info",
          beforeClose: async (action, instance, done) => {
            if (action === "confirm") {
              done();
              const requestBody = {
                title: this.selected_item.name,
                image_url: this.selected_item.updated_by,
                target_url: this.selected_item.description,
                content: this.selected_item.content,
                efective_date: this.selected_item.efective_date,
              };
              const id = this.selected_transaction.id;
              await this.$store
                .dispatch(UPDATE_LEGAL, { id, data: requestBody })
                .then(() => {
                  this.$message({
                    title: "Success",
                    type: "success",
                    message: "PDP updated",
                  });
                  this.onHide();
                  this.getList();
                })
                .catch((err) => {
                  popupErrorMessages(err.response.data);
                });
              return;
            }
            done();
          },
        }
      ).catch(() => {});
    },
    async createChannel() {
      this.company_id = parseInt(this.company_id);
      const storedImage = localStorage.getItem("uploadedImage");
      const uploadedImage = storedImage ? JSON.parse(storedImage) : null;
      try {
        const requestBody = {
          name: this.channel.name,
          type: this.channel.type,
          avatar: uploadedImage?.file_url || "",
          is_online: this.channel.is_online,
          company: {
            id: this.company_id,
            name: String(this.company_name),
          },
        };
        const response = await this.$store.dispatch(CREATE_CHANNEL, {
          data: requestBody,
        });
        this.channel = {
          name: "",
          type: "",
          avatar: "",
          is_online: "",
          company: { id: 0, name: "" },
        };

        this.data_channel = response;

        this.$message({
          title: "Success",
          type: "success",
          message: "Channel created",
        });

        this.onHide();
        this.getList();
      } catch (err) {
        popupErrorMessages(err.response?.data);
      }
    },

    async uploadImage() {
      this.company_id = parseInt(this.company_id);
      console.log("Company ID (after conversion):", this.company_id);

      try {
        const requestBody = {
          file_data: this.file_tmp,
          filename: "image-avatar",
          source: "vdashboard",
          product: "avatar",
          category: "system",
        };
        const response = await this.$store.dispatch(UPLOAD_IMAGE, {
          data: requestBody,
        });
        const { file_id, file_url } = response.data.rows;
        this.data_image = { file_id, file_url };
        localStorage.setItem("uploadedImage", JSON.stringify(this.data_image));
        this.file_tmp = "";
        this.$message({
          title: "Success",
          type: "success",
          message: "Upload success created",
        });

        this.getList();
      } catch (err) {
        console.error("Upload Image Error:", err.response?.data || err.message);
        popupErrorMessages(err.response?.data);
      }
    },
    onHide() {
      this.modalCreateChannel = false;
      this.modalUpdateChannel = false;
      this.modalUpdateLStatus = false;
    },
    capitalizeFirst(value) {
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    },
    pageChangeHandler(page) {
      this.page = page;
      this.getList();
    },
    searchHandler() {
      this.page = 1;
      this.search.action = "";
      this.search.start_date = moment(this.search.date[0]).format("YYYY-MM-DD");
      this.search.end_date = moment(this.search.date[1]).format("YYYY-MM-DD");
      this.getList();
    },
    async getList() {
      this.isLoading = true;
      await this.$store
        .dispatch(GET_CHANNEL, {
          filter: "status,all",
        })
        .catch(() => {});
      const { rows, count } = this.$store.getters.channel;
      this.total_rows = count || 0;
      this.listcategory = rows.map((v) => {
        v.birth_place_date =
          v.birth_place && v.birthday
            ? [v.birth_place, moment(v.birthday).format("DD MMM YYYY")].join(
                ", "
              )
            : "-";
        return v;
      });
      this.isLoading = false;
    },

    formatDate(timestamp) {
      return moment(timestamp).format("D MMMM YYYY, HH:mm A");
    },
    formatNumber(value) {
      return new Intl.NumberFormat("id-ID").format(value);
    },
  },
};
</script>
<style>
.el-range-separator {
  width: 10% !important;
}

.child1-sticky {
  position: sticky;
  right: 0px;
  width: 40px;
  background: #fff;
}

.child2-sticky {
  position: sticky;
  right: 69px;
  width: 255px;
  background: #fff;
}

.scrollable-card-body {
  max-height: 400px;
  overflow-y: auto;
}

.el-timeline-item__timestamp {
  color: orange;
}

.custom-card {
  width: 500px;
  height: auto;
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.extra-small-btn {
  font-size: 12px;
  /* padding: 4px 10px;  */
  height: 24px;
}
</style>
